import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ProjectListingSectionView.css';

const ProjectListingSectionView = ({ onCreateProject, userDetail }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState('us-east-2');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5); // Number of posts per page
  const navigate = useNavigate();

  const [pageItem, setPageItem] = useState({
    start: 0,
    end: postsPerPage
  });

  useEffect(() => {
    const fetchProjects = async () => {
      if (!userDetail.token || !userDetail.email) return;

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_SYNC_BACKEND_API_BASE_URL}/projects?host_user_email=${userDetail.email}`,
        headers: { 
          'Authorization': `Bearer ${userDetail.token}`
        }
      };

      try {
        const response = await axios.request(config);
        setProjects(response.data.projects);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [userDetail]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const selectRegion = (region) => {
    setSelectedRegion(region);
    setDropdownOpen(false);
  };

  const handleRowClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const onPageChangeEvent = (start, end) => {
    setPageItem({
      start: start,
      end: end
    });
  };

  const numOfPages = Math.ceil(projects.length / postsPerPage);
  const numOfButtons = Array.from({ length: numOfPages }, (_, i) => i + 1);

  const prevPageClick = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPageClick = () => {
    if (currentPage < numOfPages) setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    const value = currentPage * postsPerPage;
    onPageChangeEvent(value - postsPerPage, value);
  }, [currentPage, postsPerPage]);

  return (
    <div className="project_listing_main_container">
      <div className='project_listing_header'>
        <div className='project_listing_header_text'>Projects - <span>{projects.length}</span></div>
        <div className='project_listing_header_create_button' onClick={onCreateProject}>Create Project</div>
      </div>
      <div className='project_listing_search'>
        <div className="project_listing_search_container">
          <div className="project_listing_search_region-dropdown" onClick={toggleDropdown}>
            {selectedRegion} (Current Region) 
            <span className="project_listing_search_dropdown-arrow">&#9660;</span>
          </div>
          {dropdownOpen && (
            <div className="project_listing_search_dropdown-menu">
              <div className="project_listing_search_dropdown-item" onClick={() => selectRegion('us-east-1')}>us-east-1</div>
              <div className="project_listing_search_dropdown-item" onClick={() => selectRegion('us-west-1')}>us-west-1</div>
              <div className="project_listing_search_dropdown-item" onClick={() => selectRegion('eu-west-1')}>eu-west-1</div>
            </div>
          )}
          <div className="project_listing_search_search-box">
            <span className="project_listing_search_search-icon">&#128269;</span>
            <input type="text" placeholder="Find applications" />
          </div>
        </div>
      </div>
      <div className='project_listing_table'>
        <div className="application-container">
          <div className="application-header">
            <div className="header-item">Name</div>
            <div className="header-item">Start Date</div>
            <div className="header-item">Status</div>
          </div>
          <div className="application-content">
            {loading ? (
              <p>Loading...</p>
            ) : projects.length === 0 ? (
              <>
                <p>No applications</p>
                <p>Get started by creating an application.</p>
                <button className="create-button" onClick={onCreateProject}>Create Project</button>
              </>
            ) : (
              projects.slice(pageItem.start, pageItem.end).map((project, idx) => (
                <div 
                  key={idx} 
                  className="application-row"
                  onClick={() => handleRowClick(project.id)}
                >
                  <div className="row-item">{project.title}</div>
                  <div className="row-item">{project.start_date}</div>
                  <div className="row-item">{project.current_status}</div>
                </div>
              ))
            )}
          </div>
          <div className="pagination">
            <button disabled={currentPage === 1} onClick={prevPageClick}>Prev</button>
            {numOfButtons.map((button) => (
              <button 
                key={button} 
                className={currentPage === button ? 'active' : ''} 
                onClick={() => setCurrentPage(button)}
              >
                {button}
              </button>
            ))}
            <button disabled={currentPage === numOfPages} onClick={nextPageClick}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectListingSectionView;
