import './App.css';
import HomePage from './views/HomePage/HomePage'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import ContributionRequests from './views/ContributionRequests/ContributionRequests';
import CreateContributionRequests from './views/CreateContributionRequests/CreateContrubutionRequests';
import ProjectListing from './views/ProjectListing/ProjectListing';
import Contributions from './views/Contributions/Contributions';
import CreateProjectSectionView from "./views/CreateProjectSectionView/CreateProjectSectionView";
import ProjectDetail from "./views/ProjectDetail/ProjectDetail";
import Header from './components/Header/Header';
import Downloads from './views/Downloads/Downloads';

const App = () =>{

  const [projects, setProjects] = useState([]);
  const [dropdownVisible, setBackgroundblur] = useState(false);

  const [userDetail, setUserDetail] = useState({
    token: "",
    name: "",
    email: "",
    user_id: "",
  });

  const fetchProjects = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_SYNC_BACKEND_API_BASE_URL}/projects`,
    };

    try {
      const response = await axios.request(config);
      setProjects(response.data.projects);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const token = Cookies.get("token");
    const name = Cookies.get("name");
    const email = Cookies.get("email");
    const user_id = Cookies.get("user_id");


    if (token && name && email && user_id) {
      setUserDetail({ token, name, email, user_id });
    }
    fetchProjects();
  }, []);

  const apiUrl = process.env.REACT_APP_SYNC_BACKEND_API_BASE_URL;
  // const apiKey = process.env.REACT_APP_API_KEY;
  
  console.log("API URL:", apiUrl);
  // console.log("API Key:", apiKey);


  return (
    <div>
        <Router>
        <Header userDetail={userDetail} setUserDetail={setUserDetail} setProjects={setProjects} setBackgroundblur={setBackgroundblur} fetchProjects={fetchProjects}/>

    <Routes>
              <Route
                path="/"
                element={<HomePage userDetail={userDetail} fetchProjects={fetchProjects} setUserDetail={setUserDetail} projects={projects} setProjects={setProjects}/>}
              >

                  <Route
                    path="/create-project"
                    element={<CreateProjectSectionView userDetail={userDetail} />}
                  />
                  <Route
                    path="/contribution-requests"
                    element={<ContributionRequests userDetail={userDetail} />}
                  />
                  <Route
                  exact
                    path="/create-contribution-request"
                    element={<CreateContributionRequests userDetail={userDetail} />}
                  />
                  <Route
                    path="/ongoing-projects-list"
                    element={<ProjectListing userDetail={userDetail} />}
                  />
                  <Route
                    path="/your-contributions"
                    element={<Contributions userDetail={userDetail} />}
                  />
              </Route>
              <Route
                exact
                path="/project/:id"
                element={<ProjectDetail userDetail={userDetail} />}
              />
                <Route
                exact
                path="/downloads"
                element={<Downloads userDetail={userDetail} />}
              />
            </Routes>
    </Router>
    </div>
  );  
}

export default App;
