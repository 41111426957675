import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const SignIn = ({ setAuthPopupVisible, toggleAuthType, setUserDetail }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = JSON.stringify({
      email: email,
      password: password
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_SYNC_BACKEND_API_BASE_URL}/signin`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      setLoading(true);
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      setLoading(false);
      
      // Save to cookies
      Cookies.set('token', response.data.access_token, { expires: 7 });
      Cookies.set('name', response.data.name, { expires: 7 });
      Cookies.set('email', response.data.email, { expires: 7 });
      Cookies.set('user_id', response.data.user_id, { expires: 7 });

      const token = response.data.access_token;
      const name = response.data.name;
      const email = response.data.email;
      const user_id = response.data.user_id;
      
      setUserDetail({ token, name, email, user_id });
      setAuthPopupVisible(false);
    } catch (error) {
      console.log(error);
      alert("Sign In failed!");
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <div className="loader">Loading...</div>}
        <>
          <h2>Sign In</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Sign In</button>
          </form>
          <div className="auth_toggle">
            <p>Don't have an account? <span onClick={toggleAuthType}>Sign Up</span></p>
          </div>
          <span className="auth_popup_close" onClick={() => setAuthPopupVisible(false)}>Skip</span>
        </>
    </div>
  );
};

export default SignIn;
