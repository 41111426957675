import React, { useState, useEffect } from "react";
import "./ProjectDetail.css";
import { useParams, useNavigate } from 'react-router-dom';
import avtar from '../../assets/account.png';
import upvotes from '../../assets/upvote.png';
import share from '../../assets/data-sharing.png';
import contribute from '../../assets/contribute.png';
import import_icon from '../../assets/import.png';

import CreateContributionRequests from "../CreateContributionRequests/CreateContrubutionRequests";
import axios from 'axios';

const ProjectDetail = ({ userDetail }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [activeTab, setActiveTab] = useState('ModelCard');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isLoadingHashcode, setIsLoadingHashcode] = useState(false); // New state to track loading
  const [hashcode, setHashcode] = useState('');  // Store generated hashcode
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [copied, setCopied] = useState(false); // State to track if hashcode is copied
  const baseURL = process.env.REACT_APP_CLIENT_BASE_URL || "http://127.0.0.1:3000";  // Default to localhost if env var isn't set
  const sharableUrl = baseURL + '/project/' + project?._id;

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SYNC_BACKEND_API_BASE_URL}/project/${id}`);
        setProject(response.data);
      } catch (error) {
        console.error("Error fetching project details", error);
      }
    };

    fetchProjectDetails();
  }, [id]);

  const handleContributeClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleShareClick = () => {
    setIsShareModalOpen(true); 
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false); 
  };

  const handleImportClick = async () => {
    setIsImportModalOpen(true); // Open Import modal
    setIsLoadingHashcode(true);  // Start the loader when the modal opens

    try {
      let data = JSON.stringify({
        "projectId": project?._id,
        "userId": userDetail.user_id 
      });
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_SYNC_BACKEND_API_BASE_URL}/generate-hash`,
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${userDetail.token}`
        },
        data : data
      };
      // Call generateHashcode API
      const response = await axios.request(config);

      // Assuming API response contains the hashcode
      const generatedHashcode = response.data.hashId;
      setHashcode(generatedHashcode);  // Set the generated hashcode
    } catch (error) {
      console.error("Error generating hashcode", error);
      setHashcode('Error generating hashcode'); // Show error message if API fails
    } finally {
      setIsLoadingHashcode(false);  // Stop the loader
    }
  };

  const closeImportModal = () => {
    setIsImportModalOpen(false); 
    setHashcode(''); // Reset the hashcode
  };

  const copyHashCodeToClipboard = () => {
    navigator.clipboard.writeText(hashcode)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch(err => {
        console.error("Failed to copy hashcode", err);
      });
  };

  const copyProjectUrlToClipboard = () => {
    navigator.clipboard.writeText(sharableUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch(err => {
        console.error("Failed to copy sharable Url", err);
      });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'ModelCard':
        return (
          <div>
            <section className="model-details">
              <div className="model-information">
                <p>
                  <strong>Model Architecture:</strong> Llama 3.1 is an auto-regressive
                  language model that uses an optimized transformer architecture. The
                  tuned versions use supervised fine-tuning (SFT) and reinforcement
                  learning with human feedback (RLHF) to align with human preferences
                  for helpfulness and safety.
                </p>
              </div>
            </section>
          </div>
        );
      case 'Code':
        return <div>Code information will be displayed here.</div>;
      case 'Discussion':
        return <div>Discussion information will be displayed here.</div>;
      case 'Competitions':
        return <div>Competitions information will be displayed here.</div>;
      default:
        return null;
    }
  };

  if (!project) {
    return <div>Loading project details...</div>;
  }

  return (
    <div className="project-detail-container">
      {/* Top section with buttons */}
      <div className="project-top-header-buttons">
        {/* Author, org, and project info */}
        <div className="project-author-org">
          <img src={avtar} className="project-author-org-avatar" alt="Author Avatar"/>
          <div className="project-author-org-name">Meta</div>
          <div className="project-author-org-timestamp-wrapper">
            <div className="project-author-org-timestamp"> Created on : </div>
            <div className="project-author-org-date-value">2024.07.18</div>
          </div>
        </div>
        
        {/* Action buttons */}
        <div className="project-button-wrapper">
          <div className="project-utils-contribute" onClick={handleContributeClick}>
            <img className="project-utils-contribute-icon" src = {contribute}/>
            <span>Contribute</span>
          </div>
          <div className="project-upvotes">
            <img className="project-upvotes-icon" src = {upvotes}/>
            <span>445</span>
          </div>
          <div className="project-hashcode" onClick={handleImportClick}>
            <img src={import_icon} className="project-hashcode-import"/>
            <span>import</span>
          </div>
          <div className="project-utils-popup" onClick={handleShareClick}>
            <img className="project-utils-share" src={share}/>
            <span>Share</span>
          </div>
        </div>
      </div>
      
      <header className="project-header">
        <div className="project-title">
          <h1>{project.title}</h1>
        </div>
      </header>
      <span className="project-subtitle">{project.current_status}</span>

      <section className="project-access">
        <p>{project.short_description}</p>
      </section>
      
      {/* Tab navigation */}
      <div className="project-tabs">
        <div
          className={`project-tab-button ${activeTab === 'ModelCard' ? 'active' : ''}`}
          onClick={() => setActiveTab('ModelCard')}
        >
          Model Card
        </div>
        <div
          className={`project-tab-button ${activeTab === 'Code' ? 'active' : ''}`}
          onClick={() => setActiveTab('Code')}
        >
          Code
        </div>
        <div
          className={`project-tab-button ${activeTab === 'Discussion' ? 'active' : ''}`}
          onClick={() => setActiveTab('Discussion')}
        >
          Discussion
        </div>
        <div
          className={`project-tab-button ${activeTab === 'Competitions' ? 'active' : ''}`}
          onClick={() => setActiveTab('Competitions')}
        >
          Competitions
        </div>
      </div>
      
      <div className="project-tab-body">
        {renderTabContent()}
      </div>

      {/* Contribution Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>X</button>
            <CreateContributionRequests userDetail={userDetail} project={project} closeModal={closeModal} />
          </div>
        </div>
      )}

      {/* Import Modal */}
      {isImportModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={closeImportModal}>X</button>
            
            {/* Show loader or hashcode */}
            {isLoadingHashcode ? (
              <div className="loading-message">Wait for a few seconds, we're generating the hashcode for you...</div>
            ) : (
              <>
                <h3>Project Hashcode</h3>
                <p>Import this project on your local device in Client Application using the hashcode:</p>
                <p className="hashcode-url">{hashcode}</p>
                <button className="copy-button" onClick={copyHashCodeToClipboard}>
                  {copied ? 'Copied!' : 'Copy Hashcode'}
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Share Modal */}
      {isShareModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-button" onClick={closeShareModal}>X</button>
            <h3>Share Project</h3>
            <p>Share the project you think they can contribute :</p>
            <p className="hashcode-url">{sharableUrl}</p>
            <button className="copy-button" onClick={copyProjectUrlToClipboard}>
              {copied ? 'Copied!' : 'Copy Link'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetail;
